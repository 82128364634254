import './App.css'; 
import Home from './Pages/Home';


function App() {

  
  return (
    <div className="App"> 
      <Home/>
    </div>
  );


}

export default App;
